import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultFooterHeight } from "../Layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "270px",
      height: "calc(100% - 80px)",
      zIndex: 10,
      position: "absolute",
      top: "10px",
      left: "-270px",
      [theme.breakpoints.up("lg")]: {
        height: "calc(100% - 20px)",
      }
    },
    rootOpen: {
      left: "10px",
    },

    // tabs
    tabs: {
      height: "30px",
      display: "flex"
    },
    tab: {
      background: "white",
      borderRadius: "6px 6px 0 0",
      marginRight: "4px",
      padding: "0 20px",
      lineHeight: "30px",
      color: theme.palette.primary.main,
      fontWeight: "bold"
    },
    tabInactive: {
      background: "#F1F1F1",
      color: "#929292"
    },


    wrapper: {
      borderRadius: "0 10px 0 10px",
      padding: "10px",
      overflowY: "auto",
      background: theme.palette.common.white,
      position: "absolute",
      top: "30px",
      bottom: "0"
    },

    // toggle
    visibilityToggle: {
      width: "40px",
      height: "40px",
      background: theme.palette.primary.main,
      borderRadius: "0 10px 10px 0",
      position: "absolute",
      right: "-40px",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        "& path": {
          fill: theme.palette.common.white
        }
      },
      "&:hover": {
        background: theme.palette.common.white,
        "& svg": {
          "& path": {
            fill: theme.palette.primary.main
          }
        }
      }
    },
    visibilityToggleOpen: {
      background: theme.palette.common.white,
      "& svg": {
        transform: "rotate(180deg)",
        "& path": {
          fill: theme.palette.primary.main
        }
      },
      "&:hover": {
        background: theme.palette.primary.main,
        "& svg": {
          "& path": {
            fill: theme.palette.common.white
          }
        }
      }
    },

    // utilities
    hr: {
      marginBottom: "0.7rem",
      paddingBottom: "0.7em",
      borderBottom: "1px solid #f3f3f3"
    },
    nowrap: {
      whiteSpace: "nowrap"
    },
    my0: {
      marginTop: "0",
      marginBottom: "0"
    },
    mb0: {
      marginBottom: "0"
    },

    // typo
    mainTitle: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(15),
      marginTop: ".4em"
    },
    sectionTitle: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(13)
    },
    filterTitle: {
      fontWeight: "bold"
    },

    // table
    tableGroupHeader: {
      padding: "2px 5px",
      background: "#F3F3F3",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "space-between",
      fontSize: ".7rem"
    },
    tableRow: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: ".7rem",
      padding: "1px 5px",
      borderBottom: "1px solid #F3F3F3",
      "&:last-of-type": {
        borderBottom: "0"
      }
    },
    tableRowInactive: {
      opacity: ".2"
    },
    total: {
      padding: "5px 10px",
      background: "#EFEDE6",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem",
      marginBottom: "1rem",
      fontWeight: "bold"
    },
    color: {
      width: "10px",
      height: "14px",
      display: "inline-block",
      margin: "-1px 4px 0 0",
      verticalAlign: "middle",
      borderRadius: "3px",
    },

    // data usage
    dataUsageBox: {
      marginTop: ".7rem",
      marginBottom: ".7rem"
    },

    // filter
    filterColor: {
      width: "7px",
      height: "14px",
      margin: "-1px 5px 0 0",
      verticalAlign: "middle",
      borderRadius: "2px",
    },
    filterValue: {
      "& .MuiFormControlLabel-label": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "190px",
      }
    },

    // form
    checkbox: {
      padding: "2px 3px",
      "& .MuiSvgIcon-root": {
        width: "19px"
      }
    },
    checkboxLabel: {
      marginLeft: "-6px",
      "& .MuiFormControlLabel-label": {
        fontSize: ".7rem"
      }
    },
    radio: {
      padding: "2px 3px",
      "& .MuiSvgIcon-root": {
        width: "19px"
      }
    },
    radioLabel: {
      marginLeft: "-6px",
      "& .MuiFormControlLabel-label": {
        fontSize: ".7rem"
      }
    }
  })
);

export default useStyles;
