import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import useHeaderContent from "../../components/HeaderProvider/useHeaderContent";
import Page from "../Page/Page";
import messages from "./messages";
import pageMessages from "../messages";
import useStyles from "./styles";
import ModelingProgressWrapper from "../../components/ModelingProgress/ModelingProgressWrapper";
import { useHeaderStyles } from "../../components/Header/Header";
import { IModelingShowcaseBasic } from "../../packages/Api/data/modelingShowcase/types";
import { useTheme } from "@material-ui/core/styles";

const ModelingShowcasePage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const theme = useTheme();
    const [ showcases, setShowcases ] = useState<IModelingShowcaseBasic[]>([]);

    useEffect(() => {

    }, [keycloakInitialized]);

    // translations
    const intl = useIntl();
    const transHeaderTitle = intl.formatMessage({ ...pageMessages.pageArealDashboard });

    const code = "scvb-01";

    useEffect(() => {
        setHeaderContent(
            <>
                <Typography variant="h1" component="h1" className={headerClasses.heading}>
                    {transHeaderTitle}
                </Typography>
            </>
        );
    }, [transHeaderTitle, code, showcases]);


    return (
        <Page noPadding={true}>
            <Helmet>
                <title>{`${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={code ? classes.detailBox : classes.dashboardBox}>
                <Grid container direction="row" className={classes.grid}>
                    <ModelingProgressWrapper showcaseCode={code} />
                </Grid>
            </Box>
        </Page>
    );
};

export default ModelingShowcasePage;
