import React, {useEffect, useRef, useState} from "react";
import { Box, Button, Fab, Tooltip } from "@material-ui/core";
import { Emea, ViewerState } from "../Viewer/types";
import { useKeycloak } from "@react-keycloak/web";
import ForgeViewer from "iolabs-react-forge-viewer";
import { IModelingItemProgress } from "./ModelingProgressItem";
import useStyles from "./styles";
import { isJwtExpired } from "../../utils/Jwt";
import { viewerToken } from "../../packages/Api/data/viewer/client";
import PushpinsInViewer from "../PushpinsInViewer/PusphinsInViewer";
import PushpinPopover from "./PushpinPopover";
import Icon from "../Icon/Icon";
import { useIntl } from "react-intl";
import messages from "./messages";
import FilterBar from "../FilterBar/FilterBar";
import {
    ExtensionID as ColorizeExtensionID, ObjectsColoring,
    register as registerColorizeExtension,
} from "./../Viewer/extensions/Viewing.Extensions.ColorizeExtension/Viewing.Extensions.ColorizeExtension";
import {
    ExtensionID as OptionsExtensionID,
    register as registerOptionsExtension,
} from "../FilterBar/Viewing.Extensions.OptionsExtension";
import clsx from "clsx";
import ViewSwitcher from "../Viewer/ViewSwitcher";


interface IModelingProgress {
    modelInfo: ViewerState;
    viewerState?: any;
    items?: IModelingItemProgress[];
}

const ModelingProgress: React.FC<IModelingProgress> = ({modelInfo, viewerState, items}) => {

    const classes = useStyles();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [modelLoaded, setModelLoaded] = useState<boolean>(false);
    const [viewer, setViewer] = useState<any>();
    const [forgeToken, setForgeToken] = useState<string>();
    const [colorizeExtension, setColorizeExtension] = useState<any>();
    const [optionsExtension, setOptionsExtension] = useState<any>();

    const [ showPopovers, setShowPopovers ] = useState<boolean>(true);
    const [ showStatus, setShowStatus ] = useState<boolean>(true);
    const [ showArea, setShowArea ] = useState<boolean>(true);

    // viewables
    const [viewable, setViewable] = useState<any>();
    const [viewables, setViewables] = useState<any>();
    const viewableRef = useRef(viewable);

    // Filter bar
    const [coloringList, setColoringList] = useState<ObjectsColoring[]>();
    const [displayOnlySurfaceModel, setDisplayOnlySurfaceModel] = useState<boolean>(false);
    const [ghostHidden, setGhostHidden] = useState<boolean>(true);

    // translations
    const intl = useIntl();
    const transShowPopovers = intl.formatMessage({ ...messages.showPopovers });
    const transHidePopovers = intl.formatMessage({ ...messages.hidePopovers });
    const transButtonStatus = intl.formatMessage({ ...messages.buttonStatus });
    const transButtonSurfaces = intl.formatMessage({ ...messages.buttonSurfaces });



    const handleTokenRequested = (onAccessToken: any) => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            viewerToken(keycloak?.token as string).then((response) => {
                setForgeToken(response);
                onAccessToken(response);
            })
        }
        else {
            onAccessToken(forgeToken);
        }
    };


    const handleDocumentLoaded = (doc: Autodesk.Viewing.Document, viewables: any[]) => {
        setViewable(viewables[0]);
        setViewables(viewables);
    };

    const handleForgeScriptLoaded = () => {
        registerColorizeExtension();
        registerOptionsExtension();
    };

    const handleModelError = (error) => {
        console.error("Error loading the model: " + error);
    };

    const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
        setViewer(viewer);
    };


    const handleViewerError = (error) => {
        console.error("Error loading mobile viewer: " + error);
    };

    const handleDocumentError = (viewer: any, error: any) => {
        console.error("Error loading a document: " + error);
    };


    const initViewerSettings = (viewer: Autodesk.Viewing.Viewer3D) => {
        viewer.setLightPreset(16); // Snow Field
        viewer.navigation.setReverseZoomDirection(true);
        viewer.prefs.set(Autodesk.Viewing.Private.Prefs.ZOOM_SCROLL_SPEED, 0.15);
    };

    const initState = (viewer: Autodesk.Viewing.Viewer3D) => {
        if (viewerState) {
            viewer.restoreState(viewerState)
        }
    }

    const handleModelLoaded = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => {
        if (viewer) {
            initState(viewer)
            initViewerSettings(viewer);
        }

        viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, (selection) => {
            viewer
                .loadExtension(ColorizeExtensionID, {
                    mappingProperty: "IfcGUID"
                })
                .then((extension) => {
                    setColorizeExtension(extension);
                });

            viewer
                .loadExtension(OptionsExtensionID, {
                  setGhosting: false
                })
                .then((extension) => {
                  setOptionsExtension(extension);
                });
        });

        setModelLoaded(true);
    };

    // Filter bar
    // todo hotfix - temporary solution with first applying schema by delay 2s
    const [schemaApplied, setSchemaApplied] = useState<boolean>(false);
    useEffect(() => {

        function delay(time) {return new Promise(resolve => setTimeout(resolve, time));}
        delay(schemaApplied ? 0 : 1000).then(() => {

            // actual script
            if(colorizeExtension && coloringList && modelLoaded) {
                colorizeExtension.applySchema(coloringList, displayOnlySurfaceModel as boolean).then(() => {
                    console.log("scheme applied");
                    setSchemaApplied(true);
                });
            }

        });

    }, [coloringList, colorizeExtension, modelLoaded, displayOnlySurfaceModel]);

    const handleFilterBarColors = (filterBarColors) => {
        setColoringList(filterBarColors);
    }

    const handleFilterBarOptions = (filterBarOptions) => {
        setDisplayOnlySurfaceModel(filterBarOptions.displayOnlySurfaceModel);
        setGhostHidden(filterBarOptions.ghostHidden);
    }

    const toggleArea = () => {
        const show = !showArea
        setShowArea(show);
        if(!show) {
            if(colorizeExtension && coloringList) {
                colorizeExtension.clearSchema().then(() => {
                    console.log("scheme cleared");
                });
            }
        }
    }

    useEffect(() => {
        if(optionsExtension) {
            optionsExtension.ghostHidden(ghostHidden);
        }
    }, [ghostHidden]);
    // /Filter bar

    const handleApplyScheme = () => {
        if(colorizeExtension && coloringList) {
            colorizeExtension.applySchema(coloringList, false).then(() => {
                console.log("scheme applied");
            });
        }
    }
    const handleApplySchemeWithIsolation = () => {
        if(colorizeExtension && coloringList) {
            colorizeExtension.applySchema(coloringList, true).then(() => {
                console.log("scheme applied");
            });
        }
    }
    const handleClearScheme = () => {
        if(colorizeExtension && coloringList) {
            colorizeExtension.clearSchema().then(() => {
                console.log("scheme cleared");
            });
        }
    }

    const onUnmount = () => {};

    const togglePopovers = () => {
        setShowPopovers(!showPopovers);
    }

    const toggleStatus = () => {
        setShowStatus(!showStatus);
    }

    const preprocessViews = (views: Autodesk.Viewing.BubbleNode[]): Autodesk.Viewing.BubbleNode[] => {
        return views.filter(v => v?.data?.name?.startsWith("$")).map(v => {
            v.data.name = v.data?.name?.substring(1);
            return v;
        });
    }

    return (
        <Box className={classes.viewerWrapper}>
            {modelLoaded && showStatus && (
                <>
                    <PushpinsInViewer
                        viewer={viewer}
                        items={items}
                        renderPushpinContent={!showPopovers ? undefined : ((item, anchorElement, handleClose) => (
                            <PushpinPopover
                                anchorEl={anchorElement}
                                item={item}
                                handleClose={handleClose}
                            />
                        ))}
                        onCanvasClick={pushpin => {
                            console.log(`${pushpin.coordinates.x}, ${pushpin.coordinates.y}, ${pushpin.coordinates.z}`)
                        }}
                    />
                    <Tooltip title={showPopovers ? transHidePopovers : transShowPopovers} className={classes.toggleButton}>
                        <Fab color="primary" aria-label="toggle" onClick={togglePopovers}>
                            <Icon name={showPopovers ? "eye-solid" : "eye"} size={20} />
                        </Fab>
                    </Tooltip>
                </>

            )}

            {modelLoaded && showArea &&
              <FilterBar
                onOutputColors={handleFilterBarColors}
                onOutputOptions={handleFilterBarOptions}
              />
            }

            <ForgeViewer
                version="7.29"
                urn={modelInfo?.urn}
                api={modelInfo?.isEmea ? Emea.eu : Emea.default}
                view={viewable}
                query={{ type: "geometry" }}
                onViewerError={handleViewerError}
                onTokenRequest={handleTokenRequested}
                onDocumentLoad={handleDocumentLoaded}
                onDocumentError={handleDocumentError}
                onModelLoad={handleModelLoaded}
                onModelError={handleModelError}
                onViewerLoad={handleViewerLoaded}
                onScriptLoaded={handleForgeScriptLoaded}
                onUnmount={onUnmount}
                forgeScriptAlreadyLoaded
                config={{
                    memory: {
                        limit: 1000,
                    },
                    profileSettings: {
                        lightPreset: 0
                    }
                }}
            />
            {viewer && modelLoaded && (
                <ViewSwitcher
                    viewer={viewer}
                    preprocessViews={preprocessViews}
                />
            )}

            {/* todo remove */}
{/*            {modelLoaded && showArea &&
              <Box className={classes.testScheme}>
                  <Box onClick={handleApplySchemeWithIsolation}>
                      apply scheme with isolation
                  </Box>
                  <Box onClick={handleApplyScheme}>
                      apply scheme
                  </Box>
                  <Box  onClick={handleClearScheme}>
                      clear
                  </Box>
              </Box>
            }*/}

            <Box className={classes.navButtons}>
                <Button onClick={toggleStatus} className={clsx(classes.navButton, showStatus && classes.navButtonActive)}>{transButtonStatus}</Button>
                <Button onClick={toggleArea} className={clsx(classes.navButton, showArea && classes.navButtonActive)}>{transButtonSurfaces}</Button>
            </Box>
        </Box>
    );
};

export default ModelingProgress;
