export enum Emea {
    eu = "derivativeV2_EU",
    default = "derivativeV2",
}

export enum FileType {
    Geometry = "GeometryFile",
    Model = "ModelFile",
    Template = "ModelTemplateFile",
    Mapping = "MappingFile",
}

export enum ExternalSystem {
    Forge = "forge",
}

export type ViewerState = {
    api?: string;
    urn: string;
    isEmea: boolean;
};

export enum ViewerRole {
    "3D" = "3d",
    "2D" = "2d",
}

export enum ViewableRole {
    "3D" = "3d",
    "2D" = "2d",
}
