import React, { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Button, ButtonBase, Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";
import useStyles from "../ModelingProgress/styles";
import clsx from "clsx";

const viewThumbnails = require.context('../../assets/views', true, /[a-zA-Z0-9.-]/);

const getViewThumbnail = (code: string) => {
    return viewThumbnails(`./${code}.jpg`);
}

export interface IViewSwitcherProps {
    viewer: Autodesk.Viewing.Viewer3D;
    preprocessViews?: (view: Autodesk.Viewing.BubbleNode[]) => Autodesk.Viewing.BubbleNode[]
}

interface IView {
    name: string
}

const convertCamera = (model: Autodesk.Viewing.Model, camera: any): any  => {
    const placementWithOffset = model.getData().placementWithOffset;

    const pos = new THREE.Vector3( camera[0], camera[1], camera[2] );
    const target = new THREE.Vector3( camera[3], camera[4], camera[5] );
    const up = new THREE.Vector3( camera[6], camera[7], camera[8] );
    const aspect = camera[9];
    const fov = camera[10] / Math.PI * 180;
    const orthoScale = camera[11];
    const isPerspective = !camera[12];

    const offsetPos = pos.applyMatrix4( placementWithOffset );
    const offsetTarget = target.applyMatrix4( placementWithOffset );

    const nwSavedViewpoints = [];
    return {
            aspect: aspect,
            isPerspective: isPerspective,
            fov: fov,
            position: offsetPos,
            target: offsetTarget,
            up: up,
            orthoScale: orthoScale,
    };
}

const acr = (s) => {
    var words, acronym, nextWord;

    words = s.split(/[\s\.]+/);
    acronym= "";
    let index = 0;
    while (index<words.length) {
        nextWord = words[index];
        acronym = acronym + nextWord.charAt(0);
        index = index + 1 ;
    }
    return acronym
}

const ViewSwitcher: React.FC<PropsWithChildren<IViewSwitcherProps>> = ({ viewer, preprocessViews}) => {
    const classes = useStyles();

    const [views, setViews] = useState<Autodesk.Viewing.BubbleNode[]>([]);
    const [currentView, setCurrentView] = useState<Autodesk.Viewing.BubbleNode|undefined>();

    useEffect(() => {
        let cameras = viewer.model.getDocumentNode().search({type:'view', role: '3d'});
        if (preprocessViews) {
            cameras = preprocessViews(cameras);
        }
        setViews(cameras);
    }, [viewer, viewer.model]);

    useEffect(() => {
        if (currentView && (currentView.data as any)?.camera) {
            const camera = convertCamera(viewer.model, (currentView.data as any).camera);
            // viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, handleCameraChange);
            // setTimeout(() => {
            //     viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, handleCameraChange);
            // }, 1000);
            viewer.impl.setViewFromCamera(camera);

        }
    }, [currentView]);

    const handleCameraTransitionCompleted = () => {
        viewer.removeEventListener(Autodesk.Viewing.CAMERA_TRANSITION_COMPLETED, handleCameraTransitionCompleted);
        setTimeout(() => {
            viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, handleCameraChange);
        }, 500);

    }

    const handleCameraChange = () => {
        setCurrentView(undefined);
        viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, handleCameraChange);
    }

    const handleSetView = (e: React.MouseEvent<EventTarget>, view: Autodesk.Viewing.BubbleNode) => {
        e.stopPropagation();
        e.preventDefault();
        setCurrentView(view);
    }

    return createPortal((
        <div className={classes.viewSwitcherHolder}>
            {views.map((v, index) => {
                const code = acr(v.data?.name);
                return <Card key={`view-${index}`}>
                    <CardActionArea
                        onClick={(e) => handleSetView(e, v)}
                        className={clsx(classes.viewSwitcherHolderButton, {[classes.viewSwitcherHolderButtonActive]: v === currentView})}
                    >
                        <CardMedia
                            component="img"
                            height="120"
                            image={getViewThumbnail(code)}
                            alt={v.data?.name}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="caption" component="div">
                                {v.data?.name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            })}
        </div>
    ), viewer.container);
};

export default ViewSwitcher;
