import React, { useEffect, useState } from "react";
import ModelingProgress from "./ModelingProgress";
import { viewerProxy } from "../../packages/Api/data/viewer/client";
import { IViewerProxyResponse } from "../../packages/Api/data/viewer/types";
import { useKeycloak } from "@react-keycloak/web";
import { ViewerState } from "../Viewer/types";
import { CircularProgress } from "@material-ui/core";
import { getShowcase } from "../../packages/Api/data/modelingShowcase/client";
import { IModelingShowcase } from "../../packages/Api/data/modelingShowcase/types";

interface IModelingProgressWrapperProps {
    showcaseCode: string
}

const ModelingProgressWrapper: React.FC<IModelingProgressWrapperProps> = ({showcaseCode}) => {

    const [ modelingShowcase, setModelingShowcase ] = useState<IModelingShowcase|undefined>();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [modelInfo, setModelInfo] = useState<ViewerState>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (keycloak?.token) {
            setLoading(true);
            getShowcase(keycloak.token, showcaseCode)
                .then((response: IModelingShowcase) => {
                    setModelingShowcase(response);
                })
                .catch((error) => {
                    console.error("Error viewer proxy: " + error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        return () => {
            setModelingShowcase(undefined);
        };
    }, [showcaseCode, keycloak, keycloakInitialized]);


    useEffect(() => {
        if (keycloak?.token && modelingShowcase?.forgeFileID && modelingShowcase?.forgeProjectID) {
            setLoading(true);
            viewerProxy(keycloak.token, modelingShowcase.forgeFileID, modelingShowcase.forgeProjectID, false)
                .then((response: IViewerProxyResponse) => {
                    setModelInfo({
                        api: response?.api,
                        urn: response?.urn,
                        isEmea: response?.isEmea,
                    });
                })
                .catch((error) => {
                    console.error("Error viewer proxy: " + error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        return () => {
            setModelInfo(undefined);
        };
    }, [keycloak, keycloakInitialized, modelingShowcase?.forgeFileID, modelingShowcase?.forgeProjectID]);


    return (modelInfo && !loading && modelingShowcase && modelingShowcase.showcaseItems) ? (
        <ModelingProgress
            modelInfo={modelInfo}
            viewerState={modelingShowcase.viewerState ? JSON.parse(modelingShowcase.viewerState) : null}
            items={modelingShowcase.showcaseItems.map(si => {
                return {
                    key: si.modelingShowcaseItemID,
                    title: si.name,
                    description: si.details as string,
                    coordinates: {x: si.x, y: si.y, z: si.z} as THREE.Vector3,
                    progress: si.itemProgress,
                    modelLink: si.modelLink
                }
            })}
        />
    ) : (
        <CircularProgress />
    );
};

export default ModelingProgressWrapper;