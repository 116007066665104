 import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        itemBox: {
            zIndex: 100,
            position: "absolute",
            overflow: "visible",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
        },
        root: {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            left: 0,
            top: 0,
        },
        active: {
            display: "block"
        },
        viewerWrapper: {
            position: "relative",
            zIndex: 0,
            height: "100%",
            width: "100%",
        },
    })
);

export default useStyles;
