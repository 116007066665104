import deepmerge from "deepmerge";

export const ExtensionID = "Viewing.Extensions.OptionsExtension";

let isRegistered = false;

export interface IOptionsExtension {
  ghostHidden:boolean
}
const DefaultOptions: IOptionsExtension = {
  ghostHidden:true
};

export const register = () => {
  if (isRegistered) return;
  class OptionsExtension extends Autodesk.Viewing.Extension {

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: IOptionsExtension) {
      // @ts-ignore
      const opts = options ? deepmerge(DefaultOptions, options) : DefaultOptions;
      super(viewer, opts);
      this.viewer = viewer;
      this.viewer.setGhosting(!opts.ghostHidden)
    }

    load() {
      console.log("OptionsExtension loaded");
      return true;
    }

    unload() {
      console.log("OptionsExtension unloaded");
      return true;
    }

    public ghostHidden(ghostHidden:boolean) {
      // this.viewer.getState().renderOptions.appearance.ghostHidden
      this.viewer.setGhosting(!ghostHidden)
    }

  }


  // register extension - we need to do it here so extension is loaded by Viewer
  Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, OptionsExtension);

  isRegistered = true;

}
