import { Notifier } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { IonBackdrop, IonSpinner } from "@ionic/react";
import { useTheme } from "@material-ui/core/styles";
import { useKeycloak } from "@react-keycloak/web";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router";
import MinimalisticPage from "../../pages/MinimalisticPage/MinimalisticPage";
import PageRouter from "../../pages/PageRouter";
import DialogFactory from "../DialogFactory/DialogFactory";
import FloatingActionButton from "../FloatingActionButton/FloatingActionButton";
import FooterProvider from "../FooterProvider/FooterProvider";
import useFooterContent from "../FooterProvider/useFooterContent";
import Layout from "../Layout/Layout";
import useStyles from "./styles";
import { template } from "../../theme/Theme";

interface IProps {
    children?: any;
}

const App: React.FC<IProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const { setFooterContent } = useFooterContent();

    return (
        <>
            {!keycloakInitialized ? (
                <div className={classes.spinnerBox}>
                    <IonBackdrop tappable={false} />
                    <IonSpinner className={classes.spinnerItem} />
                </div>
            ) : (
                <SnackbarProvider
                    maxSnack={3}
                    dense
                    preventDuplicate
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    classes={{
                        variantSuccess: classes.success,
                        variantError: classes.error,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                    }}
                >
                    <Route path={"/public"} component={MinimalisticPage} />
                    <Route
                        render={() => {
                            return (
                                <>
                                    <FooterProvider>
                                        <Layout
                                            drawer={{
                                                disabled: true,
                                            }}
                                            stylesProps={{
                                                headerBarColor: template.headerBarColor,
                                                headerBarBackground: template.headerBarBackground,
                                                drawerHeaderBackground: theme.palette.primary.main,
                                                drawerBackground: theme.palette.grey["50"],
                                                footerBarColor: theme.palette.common.black,
                                                footerBarBackground: theme.palette.common.white,
                                            }}
                                        >
                                            <PageRouter />
                                        </Layout>
                                    </FooterProvider>
                                    <DialogFactory />
                                    <FloatingActionButton />
                                </>
                            );
                        }}
                    />
                    <Notifier />
                </SnackbarProvider>
            )}
        </>
    );
};

export default App;
