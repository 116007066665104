import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IViewerProxyResponse } from "./types";

const api = new ApiClient("/ForgeViewer", config.api.baseUrl);

export const viewerProxy = (
    token: string,
    urn: string,
    projectId: string,
    fetchLatest = true,
): Promise<IViewerProxyResponse> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Derivative/${encodeURIComponent(urn)}/Viewer?projectId=${projectId}&fetchLatest=${fetchLatest}`;

    return api
        .get(url)
        .then((response: AxiosResponse<IViewerProxyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const viewerToken = (
    token: string,
): Promise<string> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Viewer/Token`;

    return api.get(url)
        .then((response: AxiosResponse<string>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
