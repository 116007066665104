import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultFooterHeight } from "../Layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemBox: {
            zIndex: 100,
            position: "absolute",
            overflow: "visible",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
        },
        root: {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            pointerEvents: "none",
        },
        viewerWrapper: {
            position: "relative",
            zIndex: 0,
            height: "100%",
            width: "100%",
            "& .viewcubeWrapper": {
              top: "50px"
            }
        },
        toggleButton: {
            zIndex: 99,
            position: "fixed",
            bottom: `calc(${defaultFooterHeight}px + ${theme.spacing(10)})`,
            right: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                bottom: `calc(${defaultFooterHeight}px + ${theme.spacing(8)})`,
                right: theme.spacing(3),
            },
        },
        testScheme: {
            position: "fixed",
            top: theme.spacing(1),
            right: "450px",
            zIndex: 9999,
            '& .MuiButton-root': {
                marginRight: theme.spacing(1),
            }
        },
        navButtons: {
          position: "fixed",
          top: theme.spacing(1),
          right: theme.spacing(1),
          zIndex: 9999,
        },
        navButton: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          marginLeft: theme.spacing(1),
          color: theme.palette.common.white,
          background: theme.palette.grey["800"],
          "&:hover": {
            background: theme.palette.grey["600"],
          }
        },
        navButtonActive: {
          background: theme.palette.primary.main,
          color: theme.palette.common.white,
          "&:hover": {
            background: theme.palette.primary.dark,
          }
        },
        viewSwitcherHolder: {
            position: "absolute",
            right: theme.spacing(0.5),
            top: "200px",
            height: "calc(100% - 350px)",
            overflowY: "scroll",
            width: "250px",
            zIndex: 2,
            "& .MuiCard-root": {
                "&:not(first-of-type)": {
                    marginTop: theme.spacing(1),
                },
                "& .MuiTypography-caption": {
                    fontSize: theme.typography.pxToRem(10),
                },
                "& .MuiCardContent-root": {
                    padding: theme.spacing(1),
                    "& .MuiTypography-gutterBottom": {
                        marginBottom: 0,
                    }
                }
            }
        },
        viewSwitcherHolderButton: {
            display: 'block',
            textAlign: 'initial'
        },
        viewSwitcherHolderButtonActive: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    })
);

export default useStyles;
