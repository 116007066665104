import deepmerge from "deepmerge";
import { PropertyHelper } from "./propertyHelper";
import { ModelHelper } from "./modelHelper";

export const ExtensionID = "Viewing.Extensions.ColorizeExtension";

export interface IColorizeExtensionOptions {
    mappingProperty?: string
}

const DefaultOptions: IColorizeExtensionOptions = {};

export type ObjectsColoring = {
    color: string;
    ids: number[]|string[];
}

export const register = () => {
    class ColorizeExtension extends Autodesk.Viewing.Extension {

        private mapping?: {[key: string]: number[]};

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: IColorizeExtensionOptions) {
            // @ts-ignore
            const opts = deepmerge(DefaultOptions, options);
            super(viewer, opts);
            this.viewer = viewer;
            this.initMapping(opts?.mappingProperty);
        }

        initMapping(mappingProperty?: string) {
            if (mappingProperty) {
                // load mapping
                const ids: number[] = ModelHelper.getAllModelIds(this.viewer.model);
                PropertyHelper.getMappingIds(this.viewer.model, ids, mappingProperty).then(m => {
                    this.mapping = m;
                    console.debug("Mapping by property '%s' initialized, found %i objects", mappingProperty, Object.keys(this.mapping).length);
                }).catch(e => {
                    console.error(e);
                })
            }
            else {
                this.mapping = undefined;
            }
        }

        load() {
            console.log("ColorizeExtension loaded");
            return true;
        }

        unload() {
            console.log("ColorizeExtension unloaded");
            return true;
        }

        createColorVector(hexColor: string, intensity: number = 1): THREE.Vector4 {
            // @ts-ignore
            let color: THREE.Color = new THREE.Color(hexColor as string);
            // @ts-ignore
            return new THREE.Vector4(color.r, color.g, color.b, intensity);
        }

        public async clearSchema(
            model: Autodesk.Viewing.Model = this.viewer.model,
        ): Promise<void> {
            model.clearThemingColors();
            this.viewer.isolate([], model);
            this.viewer.impl.invalidate(true);
        }

        public async applySchema(
            colorings: ObjectsColoring[],
            isolate: boolean = true,
            model: Autodesk.Viewing.Model = this.viewer.model,
        ): Promise<void> {
            // prepare stack for monitoring which was colored
            const coloredIds: Set<number> = new Set<number>();

            if (!model) {
                throw new Error("No model defined");
            }

            // clear first
            model.clearThemingColors();
            const t0 = performance.now();

            const t1 = performance.now();
            console.debug(`Mapping took ${t1 - t0}' milliseconds.`);

            if (isolate) {
                colorings.forEach(coloring => {
                    const colorToApply = this.createColorVector(coloring.color);
                    coloring.ids.forEach(id => {
                        let dbIds: number[];
                        if (this.mapping) {
                            if (this.mapping[id]) {
                                dbIds = this.mapping[id];
                            } else {
                                console.warn("Unable to find mapping for color '%s' and ID '%s'", coloring.color, id);
                                dbIds = [];
                            }
                        } else {
                            dbIds = [id];
                        }
                        dbIds.forEach(dbId => {
                            model.setThemingColor(dbId, colorToApply, true);
                            coloredIds.add(dbId);
                        })

                    })
                });
            }

            const t2 = performance.now();
            console.debug(`Applying theming took ${t2 - t1} milliseconds.`);
            console.debug(`Color applied to ${coloredIds.size} objects.`);

            if (isolate) {
                const uniqueColoredIds: number[] = Array.from(coloredIds);
                this.viewer.isolate(uniqueColoredIds, model);
            }
            else {
                this.viewer.isolate([], model);
            }
            const t3 = performance.now();
            console.debug(`Isolating took ${t3 - t2} milliseconds.`);

            // trigger re-render
            this.viewer.impl.invalidate(true);
            const t4 = performance.now();

            console.debug(`Invalidating took ${t4 - t3} milliseconds.`);
        }
    }
    // register extension - we need to do it here so extension is loaded by Viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, ColorizeExtension);
};
