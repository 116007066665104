/**
 * Property helper
 */
export class PropertyHelper {

    public static async getMappingIds(
        model: Autodesk.Viewing.Model,
        ids: number[],
        keyAttributeName: string
    ): Promise<{ [key: string]: number[] }> {
        return new Promise<any>((resolve) => {
            // @ts-ignore
            model.getBulkProperties(ids, { propFilter: [keyAttributeName]}, (propResults) => {
                const result: { [key: string]: number[] } = {};
                propResults.forEach((propResult) => {
                    propResult.properties.forEach(prop => {
                        const id = PropertyHelper.getValue(prop) as string;
                        if (!result[id]) {
                            result[id] = [];
                        }
                        result[id].push(propResult.dbId);
                    })
                });
                resolve(result);
            });
        });
    }

    public static getInstancePropertySetFromResult(propsResult: Autodesk.Viewing.PropertyResult, skipHidden: boolean = true, addForge: boolean = true):  IInstancePropertySet[] {
        const propSets = this.getInstancePropertySet(propsResult.properties, skipHidden);

        if (addForge) {
            propSets.push({
                name: "Forge",
                properties: [
                    {
                        name: "name",
                        value: propsResult.name
                    } as IInstanceProperty,
                    {
                        name: "externalId",
                        value: propsResult.externalId
                    } as IInstanceProperty,
                    {
                        name: "objectId",
                        value: propsResult.dbId as any as string,
                    } as IInstanceProperty,
                    {
                        name: "viewerId",
                        value: propsResult.dbId as any as string,
                    } as IInstanceProperty,
                ]
            })
        }
        return propSets;
    }

    public static getInstancePropertySet(props: Autodesk.Viewing.Property[], skipHidden: boolean = true):  IInstancePropertySet[] {
        const propSets: {[key: string]: IInstancePropertySet} = { };
        props.forEach(prop => {
            if (skipHidden && prop.hidden) return;
            if (!propSets.hasOwnProperty(prop.displayCategory)) {
                propSets[prop.displayCategory] = {
                    name: prop.displayCategory,
                    properties: []
                }
            }
            propSets[prop.displayCategory].properties.push(prop);
        });
        return Object.values(propSets);
    }

    public static getName(prop: IInstanceProperty): string|undefined {
        return prop.displayName ? prop.displayName : prop.name;
    }

    public static getValue(prop: IInstanceProperty): string|number|undefined {
        return prop.displayValue ? prop.displayValue : prop.value;
    }
}

export interface IInstanceProperty extends Autodesk.Viewing.Property {
    name?: string,
    value?: string,
}
export interface IInstancePropertySet {
    name: string;
    properties: IInstanceProperty[];
}

