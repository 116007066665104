import { Fab, Tooltip } from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { DialogContext } from "../../dialogs/DialogProvider/DialogProvider";
import { DF } from "../DialogFactory/DialogFactory";
import messages from "./messages";
import useStyles from "./styles";

const FloatingActionButton: React.FC = () => {
    const classes = useStyles();
    const { toggleDialog } = useContext(DialogContext);

    // translations
    const intl = useIntl();
    const transTooltip = intl.formatMessage({ ...messages.tooltip });

    return (
        <Tooltip title={transTooltip}>
            <Fab
                color="primary"
                aria-label="feedback"
                classes={{ root: classes.fabRoot }}
                onClick={() => toggleDialog(DF.WIP)}
            >
                <QuestionAnswerIcon fontSize="small" />
            </Fab>
        </Tooltip>
    );
};

export default FloatingActionButton;
