import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useHeaderStyles } from "../../components/Header/Header";
import useHeaderContent from "../../components/HeaderProvider/useHeaderContent";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import Page from "../Page/Page";
import { AppPage, appPages, Path } from "../PageRouter";
import messages from "../messages";
import { generatePath } from "react-router";
import ModuleCards from "../../components/ModuleCards/ModuleCards";
import config from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            flex: 1,
            height: "100%",
            overflow: "auto",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "center",
            },
        },
        dashboardItem: {
            height: "300px",
            width: "200px",
            margin: theme.spacing(1),
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "space-between",
            "&:last-child": {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up("md")]: {
                height: "400px",
                width: "300px",
                margin: theme.spacing(2),
                "&:last-child": {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    })
);

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    // translations
    const intl = useIntl();
    const transHeaderTitle = intl.formatMessage({ ...messages.pageDashboard });

    useEffect(() => {
        setHeaderContent(
            <Typography variant="h1" component="h1" className={headerClasses.heading}>
                {transHeaderTitle}
            </Typography>
        );
    }, [transHeaderTitle]);

    return (
        <Page>
            <Helmet>
                <title>{`${config?.template?.title} - ${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                <ModuleCards pages={appPages?.filter((page) => page.inDashboard)} />
            </Box>
        </Page>
    );
};

export default DashboardPage;
