import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultFooterHeight } from "../Layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fabRoot: {
            position: "fixed",
            bottom: `calc(${defaultFooterHeight}px + ${theme.spacing(5)})`,
            right: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                bottom: `calc(${defaultFooterHeight}px + ${theme.spacing(3)})`,
                right: theme.spacing(3),
            },
        },
    })
);

export default useStyles;
