import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Box, Tooltip } from "@material-ui/core";
import useStyles from "./styles";
import PushpinWrapper from "./PushpinWrapper";
import clsx from "clsx";
import { createPortal } from "react-dom";

export interface IPushpinsItem {
    coordinates: THREE.Vector3;
    objectId?: number;
    key?: any;
    zIndex?: number;
}

export interface IPushpinsInViewer {
    viewer: Autodesk.Viewing.Viewer3D;
    items?: IPushpinsItem[];
    renderPushpinContent?: (item: any, anchorElement: HTMLElement | null, handleClose?: () => void) => ReactElement;
    onCanvasClick?: (pushpin: IPushpinsItem) => void,
    disableSelection?: boolean,
}


export interface IItemsListBoxInfo {
    itemsBoxInfo: IItemExtended[];
    minDepth: number;
    maxDepth: number;
}

export interface IItemExtended extends IPushpinsItem {
    clientCoords: THREE.Vector3;
    depth: number;
    zIndex?: number;
}

const PushpinsInViewer: React.FC<IPushpinsInViewer> = ({
    items,
    viewer,
    renderPushpinContent,
    onCanvasClick,
    disableSelection
}) => {

    const classes = useStyles();

    const [ extendedItems, setExtendedItems ] = useState<IItemExtended[]>([]);

    const [ modelLoaded, setModelLoaded ] = useState<boolean>(false);

    const [ pushpinAdded, setPushpinAdded] = useState<IPushpinsItem|undefined>();

    const isDragging = useRef(false);

    // add extension listeners
    useEffect(() => {
        if (viewer) {
            // mark model as loaded
            if (viewer.model) {
                setModelLoaded(true)
            }
            else {
                viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, () => {
                    setModelLoaded(true);
                });
            }
            if (disableSelection) {
                viewer.disableSelection(true);
            }
            viewer.canvas.addEventListener("click", handleViewerClick);
            viewer.canvas.addEventListener("mousedown", handleViewerMouseDown, {
                capture: true
            });
            viewer.canvas.addEventListener("mousemove", handleViewerMouseMove);

        }
        return () => {
            if (viewer) {
                viewer.canvas.removeEventListener("click", handleViewerClick);
                viewer.canvas.removeEventListener("mousedown", handleViewerMouseDown, {
                    capture: true
                });
                viewer.canvas.removeEventListener("mousemove", handleViewerMouseMove);
            }
        }
    }, [viewer]);

    useEffect(() => {
        if (viewer) {
            viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, calculatePositions);
            calculatePositions();
        }
        return () => {
            viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, calculatePositions);
        }
    }, [items]);

    useEffect(() => {
        if (pushpinAdded) {
            if (onCanvasClick) {
                onCanvasClick(pushpinAdded);
            }
            setPushpinAdded(undefined);
        }
    }, [pushpinAdded])


    const handleViewerClick = (e) => {
        if (!isDragging.current) {
            onViewerClick(e);
        }
        else {
            isDragging.current = false;
        }

    }

    const handleViewerMouseMove = (e) => {
        isDragging.current = true;
    }

    const handleViewerMouseDown = (e) => {
        isDragging.current = false;
    }


    const isInViewport = (point: any /*THREE.Vector3*/, dim: any) => {
        return 0 <= point.x && point.x <= 0 + dim.width && 0 <= point.y && point.y <= 0 + dim.height;
    };

    const calculatePositions = useCallback(() => {
        const dims = viewer.getDimensions();
        const itemsBoxesList: IItemsListBoxInfo = {
            maxDepth: 0,
            minDepth: 10000,
            itemsBoxInfo: [],
        };

        items?.forEach(item => {
            try {
                const itemsBoxInfoLocal = { ...item };


                //
                // const positionVector = new THREE.Vector3(
                //     itemsBoxInfoLocal.worldCoords.x,
                //     itemsBoxInfoLocal.worldCoords.y,
                //     itemsBoxInfoLocal.worldCoords.z
                // );

                const positionVector = item.coordinates as THREE.Vector3;

                const clientCoords = viewer.worldToClient(positionVector);
                if (isInViewport(clientCoords, dims)) {
                    const distance = viewer.getCamera().position.distanceTo(positionVector);

                    // max/min depths
                    itemsBoxesList.maxDepth = Math.max(itemsBoxesList.maxDepth, distance);
                    itemsBoxesList.minDepth = Math.min(itemsBoxesList.minDepth, distance);

                    itemsBoxesList.itemsBoxInfo.push({
                        ...itemsBoxInfoLocal,
                        clientCoords: clientCoords,
                        depth: distance,
                        zIndex: Math.round((100000 - distance) * 100)
                    });
                }
            } catch (e) {
                console.error("Error detecting sensor vector", e);
            }
        });


        setExtendedItems(itemsBoxesList.itemsBoxInfo);

    }, [items]);

    /**
     * Get client (screen) coordinates from mouse and drag event
     * @param event
     * @return Vector2
     */
    const getClientCoordsFromEvent = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.DragEvent<HTMLElement>
    ): THREE.Vector2 => {
        const clientRect = event?.currentTarget?.getBoundingClientRect();
        return {
            x: event?.clientX - clientRect?.left,
            y: event?.clientY - clientRect?.top
        } as THREE.Vector2;
    };


    /**
     * Counting correct coordinates from mouse and drag event
     * @param viewerCoords
     */
    const getWorldCoordsFromViewerCoords = (viewerCoords: THREE.Vector2): THREE.Vector3 => {
        let worldCoords = viewer.clientToWorld(viewerCoords.x, viewerCoords.y);
        if (worldCoords) {
            worldCoords = worldCoords.point;
        } else {
            worldCoords = viewer.impl.intersectGround(viewerCoords.x, viewerCoords.y);
        }
        return worldCoords;
    };

    /**
     * Get intersected object by client coords
     * @param viewerCoords
     */
    const getIntersectedObject = (viewerCoords: THREE.Vector2): number => {
        const intersection = viewer.hitTest(viewerCoords.x, viewerCoords.y, false);
        return intersection?.dbId;
    };

    const selectIntersectedObject = (viewerCoords: THREE.Vector2): number => {
        const dbId = getIntersectedObject(viewerCoords);
        if (dbId) {
            viewer.select(dbId);
        }
        return dbId;
    };

    const onViewerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {

        const viewerCoords = getClientCoordsFromEvent(event);
        const worldCoords = getWorldCoordsFromViewerCoords(viewerCoords);
        const dbId = selectIntersectedObject(viewerCoords);
        setPushpinAdded({
            coordinates: worldCoords,
            objectId: dbId
        })
        event.stopPropagation();
    }

    return createPortal((
        <Box
            className={clsx(classes.root,{[classes.active]: extendedItems && extendedItems.length > 0})}
        >
            {modelLoaded && extendedItems.map((item, index) => (
                <Box
                    key={`item-box-${item.key as string}`}
                    className={classes.itemBox}
                    style={{
                        left: `${item.clientCoords.x}px`,
                        top: `${item.clientCoords.y}px`,
                        // transform: `scale(${getScale(item)})`,
                        zIndex: item.zIndex,
                    }}
                >
                    <PushpinWrapper
                        item={item}
                        renderContent={renderPushpinContent}
                    />
                </Box>
            ))}
        </Box>
    ), viewer.container);
}
export default PushpinsInViewer;