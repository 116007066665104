import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {
    IModelingShowcase,
    IModelingShowcaseBasic,
    IModelingShowcaseItem,
    IUpdateModelingShowcaseItemResponseResponse,
} from "./types";

const modellingShowcaseApi = new ApiClient("/ModelingShowcase", config.api.baseUrl);

/**
 * Get all showcases basic information.
 * @param token
 * @param code
 */
export const getAllShowcases = (token: string): Promise<IModelingShowcaseBasic[]> => {
    ApiClient.setAuthorizationToken(token);
    return modellingShowcaseApi
        .get(`/all`)
        .then((response: AxiosResponse<IModelingShowcaseBasic[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Get showcase information.
 * @param token
 * @param code
 */
export const getShowcase = (token: string, code: string): Promise<IModelingShowcase> => {
    ApiClient.setAuthorizationToken(token);
    return modellingShowcaseApi
        .get(`/detail/${code}`)
        .then((response: AxiosResponse<IModelingShowcase>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Update showcase item information.
 * @param token
 * @param item
 */
export const updateShowcaseItem = (token: string, item: IModelingShowcaseItem): Promise<IUpdateModelingShowcaseItemResponseResponse> => {
    ApiClient.setAuthorizationToken(token);
    return modellingShowcaseApi
        .post(`/UpdateItem`, item)
        .then((response: AxiosResponse<IUpdateModelingShowcaseItemResponseResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
